import { axiosInstance } from '@/utils/fetch'
import { SubmitFeedbackRequest } from '@/types'

const SUBMIT_FEEDBACK = '/feedback'

const submitFeedback = async (payload: SubmitFeedbackRequest) => {
  const res = await axiosInstance().post<SubmitFeedbackRequest>(SUBMIT_FEEDBACK, payload)
  return res.data
}

export { SUBMIT_FEEDBACK, submitFeedback as submitFeedbackApi }
