const title = 'Interview AC';
const description =
  'Interview AC is the most advanced platform to prepare for technical interviews.';

const SEO = {
  title,
  description,
  canonical: 'https://interviewac.vercel.app/',
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: 'https://interviewac.vercel.app/',
    title,
    description,
    images: [{
      url: 'https://interviewac.vercel.app/og.png',
      secure_url: 'https://interviewac.vercel.app/og.png',
      alt: title,
      width: 1280,
      height: 720,
      type: "image/png"
    }]
  },
};

export default SEO;
