import { axiosInstance } from '@/utils/fetch'
import { RefreshTokenResponse } from '@/types'

const REFRESH_AUTH_TOKEN = '/auth/refresh'

const refreshToken = async () => {
  const res = await axiosInstance().get<RefreshTokenResponse>(REFRESH_AUTH_TOKEN)
  return res
}

export { REFRESH_AUTH_TOKEN, refreshToken as refreshTokenApi }
