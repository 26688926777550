export const COLORS = {
  text: {
    light: '#ffffff',
    dark: '#ffffff',
  },
  textRed: {
    light: '#ffcccc',
    dark: '#ffcccc',
  },
  primary: {
    light: '#1c1f28',
    dark: '#1c1f28',
  },
  darkPrimary: {
    light: '#15171e',
    dark: '#15171e'
  },
  secondary: {
    light: '#2e353d',
    dark: '#2e353d',
  },
  tertiary: {
    light: '#252c33',
    dark: '#252c33'
  },
  buttons: {
    light: '#474f5d',
    dark: '#474f5d'
  },
  midlight: {
    light: '#B0DFFF',
    dark: '#B0DFFF'
  },
  highlight: {
    light: '#50AFDF',
    dark: '#50AFDF'
  },
  iacGreen: {
    light: '#B8DA3C',
    dark: '#B8DA3C'
  },
  verdictGreen: {
    light: '#466A43',
    dark: '#466A43'
  },
  verdictRed: {
    light: '#863E41',
    dark: '#863E41'
  },
  globalVerdictGreen: {
    light: '#2B3E2F',
    dark: '#2B3E2F'
  },
  globalVerdictRed: {
    light: '#4A292E',
    dark: '#4A292E'
  },
  themeBackground: {
    light: '#1A1A1A',
    dark: '#1A1A1A'
  },
  themeForeground: {
    light: '#FFFFFF',
    dark: '#FFFFFF'
  },
  breakpoint: {
    light: '#ff6961',
    dark: '#ff6961'
  },
  inlineCodeForeground: {
    light: '#cccccc',
    dark: '#cccccc'
  },
  inlineCodeBackground: {
    light: '#2c2f38',
    dark: '#2c2f38'
  },
  codeBlock: {
    light: '#171a22',
    dark: '#171a22'
  }
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';