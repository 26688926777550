import NextApp, { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { DefaultSeo } from "next-seo";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { DarkModeProvider } from "@/context/DarkModeContext";
import { ThemeProvider } from "@chakra-ui/core";
import { init } from "@/utils/sentry";
import { persistor, store } from "../store";
import customTheme from "@/styles/chakraTheme";
import SEO from "../next-seo.config";
import "reflect-metadata";
import "@/styles/globals.css";
import "@/styles/normalize.css";
import "@/styles/markdown.css";
import "katex/dist/katex.min.css";
import "nprogress/nprogress.css";

init();

const TopProgressBar = dynamic(() => import("@/components/TopProgressBar"), {
  ssr: false,
});

function App({ Component, pageProps }: AppProps) {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={customTheme}>
          <DarkModeProvider>
            <TopProgressBar />
            <DefaultSeo {...SEO} />
            <Component {...pageProps} />
          </DarkModeProvider>
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

App.getInitialProps = async (appContext) => {
  const appProps = await NextApp.getInitialProps(appContext);
  return { ...appProps };
};

export default App;
