import { logger } from 'redux-logger'
import {
  configureStore,
  combineReducers,
  Action,
  ThunkAction,
} from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import editor from '@/modules/editor/slice'
import page from '@/modules/page/slice'
import settings from '@/modules/settings/slice'
import testSuite from '@/modules/testSuite/slice'
import ui from '@/modules/ui/slice'
import user from '@/modules/user/slice'
import { jwtRefreshMiddleware } from '@/modules/middleware/jwtRefresh'
import { RootState } from 'types/redux'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['editor', 'page', 'settings', 'ui', 'user']
}

const uiPersistConfig = {
  key: 'ui',
  storage,
  whitelist: ['splitWidth']
}

export const store = configureStore({
  reducer: persistReducer(persistConfig, combineReducers({
    editor,
    page,
    settings,
    testSuite,
    ui: persistReducer(uiPersistConfig, ui),
    user,
  })),
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({ serializableCheck: false })
      .concat(jwtRefreshMiddleware)
      .concat(process.env.NODE_ENV === 'development' ? [logger] : [])
    return middleware
  },
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
