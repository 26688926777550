import { createAsyncThunk } from '@reduxjs/toolkit'
import { SUBMIT_FEEDBACK, submitFeedbackApi } from './api/submitFeedback'
import { SubmitFeedbackRequest, SubmitFeedbackResponse } from '@/types'

type ArgType = SubmitFeedbackRequest

const submitFeedback = createAsyncThunk<SubmitFeedbackResponse, ArgType>(
  SUBMIT_FEEDBACK,
  async (args: ArgType) => {
    const res = await submitFeedbackApi(args)
    return res as SubmitFeedbackResponse
  }
)

export default submitFeedback
