import { Middleware } from 'redux';
import { AppDispatch } from 'store';
import { axiosInstance } from '@/utils/fetch';
import { logOut } from '../sharedActions';
import { REFRESH_AUTH_TOKEN } from '../thunks/api/refreshToken';
import { setAuthToken } from '../user/slice';
import { RootState } from 'types/redux';
import { RefreshTokenResponse } from '@/types';

export const jwtRefreshMiddleware: Middleware<{}, RootState> = storeApi => next => action => {
  if ((action.type as string).includes('pending')) {
    const dispatch = storeApi.dispatch as AppDispatch
    const userState = storeApi.getState().user
    const currentTime = Math.floor(Date.now() / 1000)

    const { expiresAt, refreshToken } = userState
    if (expiresAt < currentTime) {
      if (refreshToken) {
        return axiosInstance().get<RefreshTokenResponse>(REFRESH_AUTH_TOKEN)
          .then(res => {
            const { accessToken, refreshToken } = res.data
            dispatch(setAuthToken({ accessToken, refreshToken }))
            next(action)
          })
          .catch((e) => dispatch(logOut()))
      } else {
        return logOut()
      }
    }
  }

  return next(action)
}
